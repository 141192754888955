
import Header from './components/header/Header'
import Body from './components/body/Body';
import Footer from './components/footer/Footer';
import "./App.css";

function App() {
  return (
    <div className='main'>
      <Header />
      <Body />
      <Footer/>
    </div>
  );
}

export default App;
